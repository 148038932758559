@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

.left-home,
.container-logo-title-description-left-home,
.logo-ivi,
.h3-custom,
.left-home-content-icon,
.termo-adesao {
  display: none;
}

.left-home-calculator-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.left-home-content-icon {
  /* flex-grow: 1; */
}

.logo-energiav {
  width: 200px;
  z-index: 999;
}

.logo-energiav-new {
  width: 100px;
}

.logo-Ivi {
  width: 96px;
  z-index: 999;
  object-fit: cover;
  object-position: center;
}

.left-home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-home-body {
  display: flex;
  flex-direction: column;
}

.left-home-footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.left-home-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 40px;
}

.left-home-content-description {
  display: block;
}

.left-home-content-icon-container {
  display: flex;
  flex-grow: 1;
}

@media screen and (min-width: 1008px) {
  .left-home,
  .container-logo-title-description-left-home,
  .logo-ivi,
  .left-home-content-title,
  .left-home-content-icon,
  .termo-adesao {
    display: flex;
  }

  .left-home {
    /* border: 1px solid green; */
    width: 50vw;

    height: 100vh;
    padding: 2rem;
    background-color: #fff;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    transition: padding 0.5s ease;
  }
  /* .container-left-home {
    border: 1px solid yellow;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  } */
  .container-logo-title-description-left-home {
    /* border: 1px solid purple; */

    /* display: flex; */
    flex-direction: column;
    gap: 1rem;
  }

  .logo-ivi {
    width: 130px;
    z-index: 999;
  }

  .left-home-content-title {
    color: var(--Primary-Black, #000);
    font-family: "Bricolage Grotesque";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
  }
  .left-home-content-icon {
    /*   border: 1px solid blue; */
    /* min-width: 15vw; */
    /* max-width: 35vw; */
    width: 100%;
    max-width: 320px;
    transition: width 0.5s ease;
    margin: auto;
  }

  /* .container-img {
    margin: 0 auto;
    border: 1px solid blue;
  } */

  .termo-adesao {
    /* border: 1px solid blue; */

    color: var(--Secondary-Light-Grey, #727272);
    font-family: "Kumbh Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
    text-align: center;
  }
}

@media screen and (min-width: 1440px) {
  .left-home {
    padding: 4rem;
    padding-bottom: 2rem;
  }
  .left-home-content-icon {
    max-width: 520px;
    width: 100%;
  }

  /* .left-home-content-icon { */
  /*   border: 1px solid blue; */
  /* min-width: 15vw; */
  /* max-width: 35vw; */
  /* width: 60%;
    transition: width 0.5s ease;

    margin: 0 auto; */
  /* } */
}
